import React, * as react from 'react'
import { useEffect, useRef, useState } from 'react';
import { css } from "@emotion/react"
import styled from "@emotion/styled";
import slugify from "slugify"
import { Slot } from '../../entity/slot';
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from './Icon';
import { getValuePresent, valueDayHoursBySegment } from '../organisms/TableDayHours/tableDayHoursAllSegment/tableDayHoursAllSegment';
import { SegmentEnum } from '../organisms/selectionDateJour/selectionDateJour.container';
import { Close } from 'theme-ui';
import { removeOneSlot } from '../../state/contactFormState/contactFormState';
import { useHover } from '../../hook/useOver';
import merge from "./../../gatsby-plugin-theme-ui";
import { StyledCellMobile } from '../organisms/TableDayHours/tableDayHoursSegment/TableDayHours.style';
import { useDetectDevice } from '../../hook/useDetectDevice';

export const tabValueJour = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"]
export const tabValueHeure = ["Matin", "Midi", "Ap\xa0Midi", "Soir"]
const delayOverIcon = 1
interface props {
    value: Slot,
    addOrRemoveValue: (cellValue: Slot, remove: boolean) => void
    segment: SegmentEnum
}
export const Cell = ({ segment, innerRef, value, addOrRemoveValue }: props) => {
    const { slots } = useSelector((state) => {
        return state.contactForm
    })

    const isSelected = isCellActive(slots, segment, value.jour, value.heure)
    const [getState, setState] = useState(isSelected)
    const clickHandler = () => {
        setState(!getState)
        addOrRemoveValue(value, getState)
    }

    const backgroundColor = `background-color:${getColorBySegment(segment)};`
    const isMobile = useDetectDevice()
    if(isMobile)
        return (
            <StyledCellMobile ref={innerRef} css={css`${getState ? backgroundColor : "background-color:white;"}`} onClick={() => clickHandler()} ></StyledCellMobile>
        )
    return (<StyledCell ref={innerRef} css={css`${getState ? backgroundColor : "background-color:white;"}`} onClick={() => clickHandler()} ></StyledCell>)
}

export const CellAllSegment = ({ innerRef, value }: props) => {
    const dispatch = useDispatch()
    const { slots } = useSelector((state) => {
        return state.contactForm as { slots: Slot[] }
    })

    const selectedSegment = getValuePresent(slots, { jour: value.jour, heure: value.heure })

    const removeCurrentSlot = (event: Event, selectedSegment, segment: SegmentEnum) => {
        event.preventDefault()
        event.stopPropagation()

        dispatch(removeOneSlot(selectedSegment[segment]))
    }

    return (<div ref={innerRef} css={css`
     display:grid;
     grid-template-columns: 1fr 1fr;
     background:white;
     width:100%;
     box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
     margin-left:auto;
     margin-right:auto;
     justify-content: center;   
     `} >
        {selectedSegment.AIDE && <IconSelected deleteSlot={(event) => { removeCurrentSlot(event, selectedSegment, SegmentEnum.Aide) }} iconName="aide" />}
        {selectedSegment.MENAGE && <IconSelected deleteSlot={(event) => { removeCurrentSlot(event, selectedSegment, SegmentEnum.Menage) }} iconName="menage" />}
        {selectedSegment.RESTAURATION && <IconSelected deleteSlot={(event) => { removeCurrentSlot(event, selectedSegment, SegmentEnum.Restauration) }} iconName="restauration" />}
        {selectedSegment.ENFANT && <IconSelected deleteSlot={(event) => { removeCurrentSlot(event, selectedSegment, SegmentEnum.Enfant) }} iconName="enfant" />}
    </div >)
}

const IconSelected = ({ deleteSlot, iconName }) => {
    const [ref, isHover] = useHover()
    const [shouldDisplayClose, setShouldDisplayClose] = useState(false)

    useEffect(
        () => {
            setIcon(isHover as boolean)
            let timer1 = setTimeout(() => setIcon(false), delayOverIcon * 1000);
            return () => {
                clearTimeout(timer1);
            };
        },

        [isHover]
    );


    const setIcon = (hover: boolean) => {
        if (hover) {
            setShouldDisplayClose(true)
        } else {
            setShouldDisplayClose(false)
        }
    }

    const isMobile = useDetectDevice()
    if(isMobile){
    return (
        <div css={css`min-width: 24px; max-width: 36px; max-height: 36px;`} ref={ref}>
             <Icon onClick={deleteSlot} iconName={iconName} style={css`padding: 0`}/>
   
        </div>
    )}

    return (
        <div css={css`width: 3em; max-width: 48px; height: 3em; max-height: 48px;`} ref={ref}>
            {!shouldDisplayClose && <Icon onClick={deleteSlot} iconName={iconName} style={css``} />}
            {shouldDisplayClose && <Close onClick={deleteSlot} sx={{ width: "3em", maxWidth: "48px", height: "3em", maxHeight: "48px", }} />}
        </div>
    )
}

export const StyledCell = styled.div`
    border-radius:10px;
    :hover{
        box-shadow: 1px 0px 4px 3px #363636df;
    }
    padding:5px;
`;

interface propNameValue {
    propName: string
    value: string
}

const isCellActive = (values: valueDayHoursBySegment[] = [], currentSegment: SegmentEnum, jour: string, heure: string) => {
    if (!values || values.length <= 0 || !jour || !heure) return false


    const slotsSegment = values.filter(x => x.segment == currentSegment)
    const result: boolean = slotsSegment.findIndex((item: valueDayHoursBySegment) => {
        return (
            item.jour == slugify(jour, { lower: true }) &&
            item.heure == slugify(heure, { lower: true })
        )
    }
    ) > -1
    return result
}

export const getPresentValue = (values: Slot[] = [], data: propNameValue[]): Slot[] => {
    if (!values || values.length <= 0) return []

    const prop1Results: Slot[] = []

    return prop1Results
}


export const getColorBySegment = (segment: SegmentEnum) => {
    switch (segment) {
        case SegmentEnum.Aide:
            return merge.colors.aide[0]
        case SegmentEnum.Enfant:
            return merge.colors.enfant[0]
        case SegmentEnum.Menage:
            return merge.colors.menage[0]
        case SegmentEnum.Restauration:
            return merge.colors.restauration[0]
        default:
            return merge.colors.yellow
    }
}